<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">General</span>
      </template>

      <course-setting-general
        v-if="courseData"
          :courseId="courseData.data.id"
           :field-information="'general'"
        :general-data="courseData"
      />
    </b-tab>
     
      
       <b-tab>

 
      <template #title>
        <feather-icon
          icon="DollarSignIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Pricing & Availability</span>
        <feather-icon
          icon="AlertTriangleIcon"
          size="18"
          class="mr-50 ml-1 text-danger"
        />

      </template>

      <course-info-pricing
        v-if="courseData"
          :courseId="courseData.data.id"
         :field-information="'pricingandavailability'"
        :information-data="courseData"
      />
    </b-tab>
       
       <b-tab>

 
      <template #title>
        <feather-icon
          icon="CpuIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Certification & Exam</span>
      </template>

      <course-info-certification
        v-if="courseData"
          :id="courseData.data.id"
         :field-information="'pricingandavailability'"
        :information-data="courseData"
      />
    </b-tab>
     
     <b-tab>

     
      <template #title>
        <feather-icon
          icon="BellIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Course Preferences</span>
      </template>
<course-info-course-preference
        v-if="courseData"
        :courseId="courseData.data.id"
        :field-information="'coursePreferences'"
        :notification-data="courseData.data.coursePreferences"
      />
    </b-tab>
   
     <b-tab>

 
      <template #title>
        <feather-icon
          icon="InfoIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Goals</span>
      </template>

      <course-info-content-info
        v-if="courseData"
          title="Course Goals"
       :field-information="'courseGoals'"
       :id="courseData.data.id"
         :information-data="courseData.data.courseGoals"
      />
    </b-tab>
      <b-tab>

 
      <template #title>
        <feather-icon
          icon="InfoIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Course Description</span>
      </template>

      <course-info-content-info
        v-if="courseData"
          title="Course Description"
             :id="courseData.data.id"
         :field-information="'courseDescription'"
         :information-data="courseData.data.courseDescription"
      />
    </b-tab>
    
      <b-tab>

 
      <template #title>
        <feather-icon
          icon="InfoIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Learning Outcome</span>
      </template>

      <course-info-content-info
        v-if="courseData"
          title="Learning Outcomes"
             :id="courseData.data.id"
         :field-information="'courseLearningOutcomes'"
         :information-data="courseData.data.courseLearningOutcomes"
      />
    </b-tab>
     <b-tab>

 
      <template #title>
        <feather-icon
          icon="InfoIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Evaluation Form </span>
      </template>

      <course-info-content-info
        v-if="courseData"
        title="Evaluation Form"
           :id="courseData.data.id"
       :field-information="'courseEvaluationForm'"
         :information-data="courseData.data.courseEvaluationForm"
      />
    </b-tab>
     <b-tab>

 
      <template #title>
        <feather-icon
          icon="InfoIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Book Reference</span>
      </template>

      <course-info-content-info
        v-if="courseData"
           title="Book Reference"
              :id="courseData.data.id"
        :field-information="'courseBookReference'"
        :information-data="courseData.data.courseBookReference"
      />
    </b-tab>
      <b-tab>

 
      <template #title>
        <feather-icon
          icon="InfoIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Activity & Participant </span>
      </template>

      <course-info-content-info
        v-if="courseData"
          title="Activity & Participant"
             :id="courseData.data.id"
      :field-information="'courseActivity'"
         :information-data="courseData.data.courseActivity"
      />
    </b-tab>
       
   
   
    
    
      <!-- 
    

    -->
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import courseStoreModule from '../courseStoreModule'
import CourseSettingGeneral from './CourseInfoGeneral.vue' 
import CourseSettingInformation from './CourseInfoInformation.vue' 
import CourseSettingNotification from './CourseInfoNotification.vue'
import CourseInfoContentInfo from './CourseInfoContentInfo.vue'
import CourseInfoCoursePreference from './CourseInfoCoursePreference.vue'
import CourseInfoPricing from './CourseInfoPricing.vue'
import CourseInfoCertification from './CourseInfoCertification.vue'

export default {
  components: {
    BTabs,
    BTab,
    CourseSettingGeneral,
   
    CourseSettingInformation,
 
    CourseSettingNotification,
    CourseInfoCoursePreference,
    CourseInfoPricing,
    CourseInfoCertification,
    CourseInfoContentInfo
  },
 
   setup() {
    const courseData = ref(null)

    const USER_APP_STORE_MODULE_NAME = 'app-course-edit'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, courseStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-course-edit/fetchCourse', { id: router.currentRoute.params.id })
      .then(response => {
   //    var course=JSON.parse(JSON.stringify(response.data));
      //  const { course  } = response.data
        courseData.value =  response.data;
        //console.log("courses assigned to course data");
        // //console.log(response.data);
        
        
        })
      .catch(error => {
        if (error.response.status === 404) {
          courseData.value = undefined
        }
      })

    return {
      courseData,
    }
  },
  data() {
    return {
      courseInfo: JSON.parse(JSON.stringify(this.courseData)), 
      }
      },
 
  beforeCreate() {
     
  // this.courseInfo=this.courseData;
 

 


  return;
   },
}
</script>
